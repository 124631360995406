// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
$font-family-base: "Open Sans", "Sans Serif", "Helvetica Neue", Arial;

$bg: #f9f9f9;

$white: #FFFFFF;
$gray-100: #F9FBFD;
$gray-200: #F1F4F8;
$gray-300: #D9E2EF;
$gray-400: #C6D3E6;
$gray-500: #ABBCD5;
$gray-600: #869AB8;
$gray-650: #7A90A7;
$gray-700: #506690;
$gray-800: #384C74;
$gray-900: #1B2A4E;
$black: #161C2D;

$crit-comment: #fffdde;
$secondary:     $gray-600;
$dark:          $gray-900;

$primary: #255686;
$primary-desat: #6C8AEC; // NEW
$success: #42BA96;
$info: #7C69EF;
$warning: #FAD776;
$light: $gray-300;
$primary-high: #487096;
$primary-medium: #7499bd;
$primary-low: #e3ebf2;
$primary-low-mid-or-secondary-high: #acc2d7;
$primary-low-mid-or-secondary-low: #acc2d7;
$primary-or-primary-low-mid: #203243;
$primary-low-mid: #acc2d7;
$secondary-low: #474747;
$secondary-medium: #777;
$secondary-high: #9b9b9b;
$secondary-very-high: #ddd;
$tertiary: #05a;
$highlight: #66addb;
$danger: #bf3c3c;
$premium: #008822;
$premium-gold: #DD8C00;
$star: #ff8800;
$moderator: #009be2;
$admin: #000000;
//$danger-hover: 

$story-size-h1: 150%;
$story-size-h2: 130%;
$story-size-h3: 115%;

$font-size-base: 0.938rem;
$font-size-xs: 0.715rem;
$font-size-sm: 0.815rem;
$font-size-lg: 1.063rem;


$h1-font-size: 2.3125rem; // 37px
$h2-font-size: 1.9375rem; // 31px
$h3-font-size: 1.5125rem; // 21px
$h4-font-size: 1.1875rem; // 19px
$h5-font-size: 1.0625rem; // 17px
$h6-font-size: 0.85rem; // 12px

$display-font-sizes: (
  1: 2.3125rem,
  2: 1.9375rem,
  3: 1.5125rem,
  4: 1.1875rem
);

$display1-font-size-md: 2.1125rem;
$display2-font-size-md: 1.7375rem;
$display3-font-size-md: 1.3125rem;
$display4-font-size-md: 1.0875rem;


$btn-line-height-sm: 1.0;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;

$table-bg: transparent;
$table-accent-bg: transparent;
$table-striped-bg-factor: 0.05;
$table-striped-bg: rgba(#597ece, $table-striped-bg-factor);
$table-hover-bg-factor:0.075;
$table-hover-bg: rgba($black, $table-hover-bg-factor);

$input-btn-padding-y: .4rem;
$input-btn-padding-x: 1.0rem;

$input-btn-padding-y-sm: .3rem;
$input-btn-padding-x-sm: 0.9rem;

$input-btn-padding-y-lg: 0.9rem;
$input-btn-padding-x-lg: 1.0rem;

//$border-radius: 0px;

$modal-inner-padding: 1.0rem;

$alert-padding-y: .5rem;
$alert-padding-x: 0.75rem;

$alert-bg-scale: -30%;
$alert-border-scale: -20%;
$alert-color-scale: -40%;

$min-contrast-ratio: 1.75;

$accordion-icon-width: 1.5em;


$badge-padding-y: .25rem;
$badge-padding-x: .5rem;

$border-color: $gray-400;

$alert-padding-y: 0.2rem;

$tooltip-border-radius:0;

$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: 1rem;
$dropdown-font-size: 0.9rem;
$dropdown-padding-y: 0.5rem;
$dropdown-padding-x: 0;
$dropdown-link-color: $gray-700;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: $gray-200;