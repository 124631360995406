// 
// user.scss
// Use this to write your custom SCSS
//
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

table {
    th {
      font-weight: normal;
      color: $primary-medium;
      text-align: left;
      padding-bottom: 0.1em !important;
    }
  }

  .btn-default {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: 0px;
    padding: 0.53em 0.8em;
    border: none;
    font-weight: normal;
    font-size: var(--font-0);
    line-height: normal;
    color: $primary;
    background: $primary-low;
    cursor: pointer;
    transition: all 0.25s;
    i {
        color: $primary-high;
        margin-right:0.45em;
        transition: all 0.25s;
    }
    &:hover {
        color: $secondary;
        background: $primary-medium;
    }
    &:hover i {
        color: $primary-low;
    }
  }

  a {
      color:$tertiary;
  }

  .usr_prem
  {
      color: $premium;
  }

  .usr_gold
  {
      color: $premium-gold;
  }

  
.controls {
    padding: 0 0 12px 0;
    float: right;
    max-width: 13.5em;
    ul {
        list-style-type: none;
        margin-top: 0;
        box-shadow: 0px 0px 15px #ccc;
        padding-left:0px;
        margin-left:10px;
    }
    li {
        font-size: 0.8rem;
        padding:0px;
    }
    .btn {
        margin: 3px;
        padding: 3px 10px 4px 10px;
        font-size: 1.0em;
        font-weight: normal;
        width: 140px;
        font-size: 0.85rem;
        margin: 0px;
        text-align: left;
        border:0px;
        color:$primary;
        transition: all 0.25s;
        &:hover {
            color: $secondary;
            background: $primary-medium;
        }
        &:hover i {
            color: $primary-low;
        }
    }
    i {
        width:17px;
        color: $primary-high;
        transition: all 0.25s;
    }

}
.user-main {
    dl {
        margin: 0;
    }

    dl div {
        align-items: baseline;
        display: inline-flex;
    }
    dt {
        color: $gray-600;
        margin-right: 5px;
        display: inline-block;
        font-weight: 300;
    }
    dd {
        padding: 0;
        margin: 0 15px 0 0;
        color: $primary;
    }
    .primary {
        width: 100%;
        position: relative;
        .primary-textual {
            padding: 0 4px 4px;
            h1 {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 0.5rem;
            }
        }
        .location-and-website {
            display: flex;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .user-profile-avatar {
        position: relative;
        float: left;
        margin: 0 20px 10px 0;
    }
    img.useravatar {

    }
    .about {
        .details {
            h2 {
                font-weight: normal;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding:0px;
                margin-bottom: 0.3rem;
                font-size:1.3em;
            }
            h3 {
                font-weight: normal;
                margin-bottom: 0.5em;
                font-size: 1.1em;
            }
            .bio {
                max-width: 750px;
                max-height: 300px;
                overflow: auto;
            }
        }
        .secondary {
            border-top: 1px solid $primary-low;
            border-bottom: 1px solid $primary-low;
            padding: 5px;
            margin-bottom: 10px;

            div {
                display: inline-flex;
                align-items: baseline;
                margin-right: 15px;
                margin: 4px 0;
            }
        }
    }
}

.bg-premium {
    color: $white;
    background-color:$premium;
}

.bg-premium-gold {
    color: $white;
    background-color:$premium-gold;
}

.bg-star {
    color: $primary-low;
    background-color:$star;
}

.bg-moderator {
    color: $primary-low;
    background-color:$moderator;
}

.bg-admin {
    color: $primary-low;
    background-color:$admin;
}

.nav-pills {
    .active {
        background-color: $primary-high !important;
    }
    .nav-link {
        border-radius: 0px !important;
        padding: 0.25rem 0.8rem;
        &:hover {
            background-color: $primary-low !important;
            color: $secondary;
        }
    }
}

.card-story {
    cursor: pointer;
    margin:0.5em;
    h2 {
        font-size: 1.1rem !important;
    }
    h1 {
        font-size: 1.2rem !important;
    }
    .card > * {
        padding:0.5em;
    }
    .card-footer {
        font-size: 90%;
    }

    .card-header {
        background-color:$primary-low-mid;
        color: $primary;
        font-size: 0.8rem;
    }
    
}  

.card-blog {
    margin:0.5em;
    .card > * {
        padding:0.5em;
    }
    .card-footer {
        font-size: 90%;
    }

    .card-header {
        background-color:$primary-low-mid;
        color: $primary;
        font-size: 0.8rem;
    }
    
}  

.ql-container {
    font-family: inherit;
    font-size: inherit;
}
.quilltoolbar .ql-picker {
    top:-3px
}
.quilltoolbar {
    border-bottom:0px;
}
.quilltoolbar button {
    background-color: transparent;
}

.ql-size-small {
font-size: 0.75em;
}
.ql-size-large {
font-size: 1.5em;
}
.ql-size-huge {
font-size: 2.5em;
}

body {
    background-color: $bg;
}
.card-body {
    font-size: 0.8rem;
    .bio {
        color: $gray-600;
        font-size:$font-size-xs;
        display: inline-block;
        text-overflow: ellipsis;
        max-height: 80px;
        overflow: hidden;;
    }
    .key {
        color: $gray-600;
        min-width:30px;
        display: inline-block;
        margin-right:5px;
    }
    .val {
        color: $primary;
        display: inline-block;
        margin-right:20px;
    }
    .notes {
        color: $gray-600;
        font-size:$font-size-sm;
        display: inline-block;
        text-overflow: ellipsis;
        //max-height: 40px;
        overflow: hidden;;
    }
    hr {
        padding:0px;
        margin:3px 0px;
    }
}

.funinfo-container {
    display: flex;
    flex-wrap: wrap;
}
.funinfo-category {
    width:100%;
    padding:1rem;
    flex-basis: 100%;
    flex-grow:1;
    //max-height: 200px;
    overflow: auto;
    box-shadow: $box-shadow;
    margin:.5rem 0rem 0.5rem 0rem;
    background-color: $white;
}
.avatar .hoverlink:hover {
    background-color: transparent;
    border: transparent;
}

.navbar-brand-img {
    background-image: url(/static/img/cclogomain.png);
    background-size: 210px;
    width: 210px;
    background-repeat: no-repeat;
    margin-bottom: 2px;
}
@media (min-width: 500px) {
    .navbar-brand-img {
        width: 210px;
    }
}

.topsplash-container {
    margin-top: 50px;
}

.text-light {
    color: $bg !important;   
}

.btn-card {
    padding:2px 4px 2px 4px;
    border-radius: 0;
    font-size:$font-size-xs;
    background-color: $primary-low;
    color: $primary;
}
.btn-card:hover {
    background-color: $primary-high;
    color: $primary-low;
}

.form-switch-sm .form-check-input {
    height:1.0rem;
    width:2.0rem;
    margin-left: -2.4rem;
}

.critbtn {
    width: 100%;
    border-radius: 0;
    padding: 0.8em;
}

.revisebtn { 
    padding:0.4em;
    margin-bottom:0.1em;
    width: 100%;
    border-radius: 0;;
}

.outerstorycontainer {
    display: flex;
    flex-wrap: nowrap;
    .blurred {
        color: transparent;
        text-shadow: 0 0 10px rgba(0,0,0,0.5);
        max-height: 600px;
        overflow: clip;
        position: relative;
    }
    .collapsed p {
        line-height: 90%;
    }
    .stack-message {
        background: var(--bs-light);
        position: absolute;
        width: 100%;
        opacity: 0.75;
        top: 80px;
        margin: auto;
        border: 1px solid var(--bs-dark);
        border-radius: 10px;
        color: var(--bs-dark);
        padding: 2em;
        vertical-align: middle;
        font-size: 1.1em;
        font-family: Open Sans;
        text-align: center;
        text-shadow:none;
    }
    .story-sidemenu[data-visible="true"]
    {
        display:block;
    }


    .story-sidemenu {
        @media screen and (max-width: 900px) {
            position: absolute;
            right: 0;
            display:none;
        }
        @media screen and (min-width: 900px) {
              display:block !important
        }
        flex-basis: 190px;
        .critbtncontainer {
            @media screen and (max-width: 900px) {
                display: none;
              }
        }
        .optiongroup {
            box-shadow: 0 0.3rem 1.5rem rgba(22, 28, 45, 0.1);
            margin-left:5px;
            background-color: white;
            margin-bottom:15px
        }
        .btn-option {
            width:100%;
            margin:4px 0 4px 0;
            text-align: left;
            color: $primary-high;
            padding: 4px 8px;
            font-size: $font-size-sm;
            cursor: pointer;
            display: block;
            &:hover {
                color: $primary-low;
                background: $primary-medium;  
            }
            white-space:nowrap;
        }
        i {
            width:1em;
            text-align: center;
            margin-right: 0.3em;
        }
        ul {
            label {
                font-size:0.7rem;
                text-indent:0.5em;
            }

            list-style: none;
            padding: 0;
            margin: 0 0 0 0;
            .header {
                background-color: $primary-low;
                padding: 4px 8px;
                font-size: $font-size-sm;
                color:$primary;
            }
            .info {
                padding: 4px 8px;
                font-size: $font-size-sm;
                color:$primary;
            }
            dl {
                display: inline-flex;
                margin:0;
            }
            dt {
                color: $gray-600;
                margin-right: 5px;
                display: inline-block;
                text-align: right;
                min-width: 50px;
            }
            dd {
                padding: 0;
                margin: 0 15px 0 0;
                color: $primary;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

.form-select-xs {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.3em;
    font-size: 1em;
    border-radius: 0;
    margin-bottom:0;
}


.storycontainer {
    max-width: 900px;
    padding: 2em var(--story-padding);
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
    width: 100%;
    font-size: var(--story-font-size);
    font-family: var(--story-font);
    margin: 0px auto;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--story-color);
    background-color: var(--story-background-color);

    .missing-paragraph {
        border: 1px solid $danger;
    }
    .comment-label {
        font-size: 90%;
        margin: 0.5em 0 0 0;
        color: #555;
        padding: 0 4px 0 4px;
    }
    .startendcomment {
        border-radius: 5px;
        margin: 1em 0 !important;
    }

    .commented {
        background-color: #e1ebf7;
    }

    h1 {
        font-size: $story-size-h1;
        padding-bottom: 1em;
        margin-bottom: 0;
    }
    h2 {
        font-size: $story-size-h2;
        padding-bottom: 1em;
        margin-bottom: 0;
    }
    h3 {
        font-size: $story-size-h3;
        padding-bottom: 1em;
        margin-bottom: 0;
    }
    p {
        padding: 0 0.2em 0 0.2em;
        margin:0;
        text-indent: var(--story-text-indent);
        line-height: var(--story-line-height);
        text-align: var(--story-text-align, left);
    }
    blockquote {
        padding: 0 0.2em 0 1em !important;
        margin:0 0 0 1em !important;
        text-indent: 0 !important;
        font-size:    var(--story-font-size) !important;
        border-radius: 0 !important;
        border-left: 3px solid $gray-600 !important;
        color: $gray-600;
    }

    .storytitle {
        margin-bottom:0em;
        padding:0;
        text-align: center;
        font-size:150% !important;
    }
    .chaptertitle {
        margin:1em 0em;
        padding:0;
        text-align: center;
        font-size:120% !important;
    }
    .storyauthor {
        font-size: 90%;;
        padding-top:0.1em;
        text-align: center;
        margin-bottom:0.7em;
    }
    .storydetails {
        font-size: 90%;;
        color:var(--bs-gray);
        padding:0.1em 0 0.4em 0;
        text-align: center;
    }

    .storynotes {
        font-size: 90%;
        color:$gray-700;
        padding:0.4em 0.1em 0.4em 0.1em;
        border-top:1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin:0.5em 0 0.5em 0;
    }

    .storynotes p {
        text-indent: 0 !important;
    }


    .comment {
        padding-top: 6px !important;
        background-color: $crit-comment;
        margin-top: 0px;
        margin-bottom: 5px;
        padding-bottom:0.5em;
    }
}


.ql-editor {
    h1 {
        font-size: $story-size-h1 !important;
    }
    h2 {
        font-size: $story-size-h2 !important;
    }
    h3 {
        font-size: $story-size-h3 !important;
    }
    blockquote
    {
        text-indent: var(--story-text-indent) !important;
        line-height: var(--story-line-height) !important;
        font-size: var(--story-font-size) !important;
    }
}

.ql-formats {
    @media screen and (max-width: 900px) {
        margin:0 !important;
    }
}

.critStatusBanner {
    background-color: var(--story-background-color);
    z-index:999;
    margin: auto;
    width:100%;
    max-width: 900px;
    border-bottom: 1px solid #eee;
}

.form-label {
    font-size:90%;
    margin:0.5em 0 0 0;
    color:#555;
    padding:0 4px 0 4px;
}

.accordion-button::after {
    margin-left: auto !important;
}

.submitHeader {
    max-width: 900px;
    margin:auto;
    margin-bottom: 1em;
}

.submitHeader > .buttons {
    width:250px;
    margin: auto; 
    white-space: nowrap;
}

.text-gold {
    color:orange !important;
}

.bg-gold {
    background-color:orange !important;
}
.text-like {
    color:$danger;
}

.text-faint {
    color:rgba(0, 0, 0, 0.1);
}

.critmarker {
    margin:0 0.2em;
}

.adult-banner {
    text-align: center;
    font-size: 1.1rem;
    font-family: verdana;
    box-shadow: 0px 0px 10px #00000052;
    opacity: 0.9;
    padding: 5px;
}

.form-short-number {
    max-width:4em;
    text-align: center;
}

.form-chapters {
    max-width:6em;
    text-align: center;
}

.form-story-title {
    font-size:120%;
    font-weight: bold;
}

.quilloutercontainer {
    border: $input-border-width solid $border-color;
    min-height: 140px;
    max-height: 800px;
    background-color: $white;
    resize: vertical;
    overflow: auto;
}
.quillcontainer {
    height:100%;
}
.quilltoolbar {
    border-bottom: $input-border-width solid $border-color !important;
    padding: 2px 5px 3px 5px !important;
    border: 0;
}

.btn-xs {
    padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
    font-size: $input-btn-font-size-xs;
    border-radius: 0;
}

h5 {
    margin-top: 0.5em;
}

.story-format-selectors .header {
    background-color: #e3ebf2;
    padding: 4px 8px;
    font-size: 0.815rem;
    color: #1d4c79;
}
.story-format-selectors .info {
    padding: 4px 8px;
    font-size: 0.815rem;
    color: #1d4c79;
}
.story-format-selectors ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;
}

.mobilemenu .revisebtn {
    width:auto
}

.toast-message {

}

.toast-warning {
    
}

#view-novel-link {
    font-size: 0.8em;
    float:right;
    cursor: pointer;
    color:$primary;
    margin: 0;
}

.novel-label {
    float:right;
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.8rem;
    color:$secondary;
}

.btn-paragraph-action {
    padding:4px 8px;
    border: 0;
    color:$white;
    background-color: transparent;
    font-size:1.2rem;
}


.btn-paragraph-action:hover {
    border-color: $primary;
    color:$white;
    background-color: $primary;
}

.badge-lg {
    padding: .6em 1.0em;
    font-size: 0.9rem;
}

.btn-outline-warning {
    border-color: #eebb2e;
    color: #eebb2e;
  }